import React, { useEffect, useState } from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { DefaultButton, Icon, TooltipHost } from '@fluentui/react';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import { Company } from '../../../system/company/Company';
import ProjectConfigurationDistributionForm from './ProjectConfigurationDistributionForm';
import CustomInputIfc from '../../../lib/FormValidator/Form/CustomInputIfc';
import { ProjectCompany } from '../../../system/projects/ProjectCompany';
import ToggleInput from '../../../lib/FormValidator/Form/ToggleInput';
import ProjectConfigurationForm from './ProjectConfigurationForm';
import InputField from '../../../lib/FormValidator/Form/InputField';
import { ProjectCompanyInDistribution } from '../../../system/projects/IProjectCompanyInDistribution';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react';
import useForceUpdate from '../../../common/custom-hooks/useForceUpdate';
import ErrorListForForm from '../../../lib/FormValidator/Form/ErrorListForForm';
import { useTranslation } from 'react-i18next';
import ComboBoxSearch from '../../Common/ComboBoxSearch';
import { routes } from '../../../common/constants';
import { useNavigate } from 'react-router-dom';
import { User } from '../../../system/User';
import useClipboardExcel from '../../../common/custom-hooks/useClipboardExcel';
import ToastErrorAndExit from '../../Collaborator/CollaboratorView/updateCollaborator/toastErrorAndExit';

interface IProjectConfigurationDistribution {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  companyList: Company[];
  formProjectDistribution: ProjectConfigurationDistributionForm;
  formProject: ProjectConfigurationForm;
  onBlurCustomInput: (customInput: CustomInputIfc) => void;
  billable: boolean;
  projectDistribution: ProjectCompanyInDistribution[];
  onChangeDistribution: (items: ProjectCompanyInDistribution[]) => void;
  user: User;
}

export const ProjectConfigurationDistribution = (props: IProjectConfigurationDistribution) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [companyOptionsFiltered, setCompanyOptionsFiltered] = React.useState<Company[]>([]);
  const forceUpdate = useForceUpdate();
  const { isActive, handleCloseActive, copyToClipboard, pasteFromClipboard } = useClipboardExcel();
  const [NotCompanies, setNotCompanies] = useState<any[]>();

  const companyOptions = React.useMemo(() => {
    return props.companyList;
  }, [props.companyList]);

  const getTotalPercent = () => {
    let percent: number = 0;
    if (props.projectDistribution && props.formProjectDistribution)
      props.projectDistribution.map((projectCompany, i) => {
        if (
            projectCompany &&
            props.formProjectDistribution
                ?.getInputDistribucionFacturableItems()
                ?.getFormFor(projectCompany)
                ?.getPercentageDistributionInput()
                ?.getValue()
        ) {
          percent += parseFloat(
              props.formProjectDistribution
                  .getInputDistribucionFacturableItems()
                  .getFormFor(projectCompany)
                  .getPercentageDistributionInput()
                  .getValue()
          )

          return (percent);
        }
      });

    percent = parseFloat(percent.toFixed(2));
    if (percent >= 99.99 && percent < 100) {
      percent = 100;
    }

    return percent;
  };

  useEffect(() => {
    getTotalPercent();
  });

  const setCompanyListOutsideProject = (val?: string) => {
    const _projectCompanyList = props.formProjectDistribution.getInputDistribucionFacturableItems();
    const ret = companyOptions.filter((com) => {
      const companyIsInProject = _projectCompanyList.hasCompany(com);

      const sameBillableType =
          props.billable === com.getAllow_billable_project() ||
          !props.billable === com.getAllow_no_billable_project();

      return !companyIsInProject && com.tradeNameLike(val) && sameBillableType && !com.getDeleted();
    });
    setCompanyOptionsFiltered(ret);
  };

  React.useEffect(() => {
    setCompanyListOutsideProject();
  }, [props.projectDistribution]);

  const onChangeCompany = () => {
    props.onChangeDistribution(
        props.formProjectDistribution.getInputDistribucionFacturableItems().getActive()
    );
    setCompanyListOutsideProject();
  };

  const onClickAssignationUniform = () => {
    props.onChangeDistribution(props.formProjectDistribution.uniformAssignment());
    forceUpdate();
  };

  const onDeleteItem = (company: ProjectCompanyInDistribution) => {
    props.formProjectDistribution.getInputDistribucionFacturableItems().delete(company);
    onChangeCompany();
  };

  const onBlurPercentage = (customInput: CustomInputIfc, projectCompany: ProjectCompanyInDistribution) => {
    forceUpdate();
    props.formProjectDistribution.getInputDistribucionFacturableItems().syncWithForm(projectCompany);
    props.onBlurCustomInput(customInput);
  };

  const onSelectCompany = (companyId: string) => {
    const com = companyOptionsFiltered.find((item) => item.isIdentifiedBy(companyId));
    props.formProjectDistribution.getInputDistribucionFacturableItems().add(ProjectCompany.with(0, com!));
    onChangeCompany();
  };

  const onChangeCanViewDistribution = (checked: boolean) => {
    if (!checked) {
      props.formProject.getCanEditDistributionInput().setValue(checked);
      props.onBlurCustomInput(props.formProject.getCanEditDistributionInput());
      forceUpdate();
    }
  };

  const onChangeCanEditDistribution = (checked: boolean) => {
    if (checked) {
      props.formProject.getCanViewDistributionInput().setValue(checked);
      props.onBlurCustomInput(props.formProject.getCanViewDistributionInput());
      forceUpdate();
    }
  };

  const onSelectCompanyType = (type: string) => {
    console.log(type)
    let allCompanyOptions: Company[] = companyOptionsFiltered;
    props.projectDistribution.forEach((project) => {
      allCompanyOptions.forEach((company, index) => {
        if (project.getCompany().getId() === company.getId()) {
          allCompanyOptions.splice(index, 1);
        }
      });
    });
    allCompanyOptions.forEach((company, index) => {
      if (company.getType() === type && !company.getDeleted()) {
        onSelectCompany(company.getId());
      }
    });
  };

  const dropdownOptions: IDropdownOption[] = companyOptionsFiltered.reduce(
      (options: IDropdownOption[], comOption: Company) => {
        const optionExists = options.some((option) => option.key === comOption.getType());
        if (!optionExists) {
          const newOption: IDropdownOption = {
            key: comOption.getType(),
            text: t(comOption.getType()),
          };
          options.push(newOption);
        }
        return options;
      },
      []
  );

  const dataToExcel = () => {
    const dataExcel: any[][] = [];

    props.projectDistribution.forEach((projectCompany) => {
      const companyName = projectCompany.getCompany().getName();
      const percentageDistribution: any = props.formProjectDistribution
      .getInputDistribucionFacturableItems()
      .getFormFor(projectCompany)
      .getPercentageDistributionInput();
      dataExcel.push([companyName, percentageDistribution.propertyValue]);
    });

    return dataExcel;
  };

  const dataFromExcel = async () => {
    onSelectCompanyType('Vinculada');
    await pasteFromClipboard((processedData) => {
      updateDistribution(processedData);
      listcompaniesIsNotRegister(processedData);
    });
  };

  const updateDistribution = (data:any[]) => {
    props.onChangeDistribution(props.formProjectDistribution.updatePercentage(data));
    forceUpdate();
  };

  const listcompaniesIsNotRegister = (data:any[]) => {
    setNotCompanies(props.formProjectDistribution.companiesIsNotRegister(data));
  }

  return (
    <div className="ms-Grid bg-white-h100 ms-depth-4" dir="ltr">
      <div className="ms-Grid-row padding-1">
        <div className="ms-Grid-col ms-sm12">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm4 ">
              <Icon iconName="CityNext2" className="text-icon" />
              <Text
                variant={"large"}
                className={"project-config-section-title"}
              >
                {t("Empresas usuarias")}
              </Text>
            </div>
            <div className="ms-Grid-col ms-sm4 ">
              {companyOptionsFiltered && (
                <Dropdown
                  placeholder={t("Agregar compañías del tipo")}
                  defaultValue={t("Agregar compañías del tipo")}
                  options={dropdownOptions}
                  selectedKey={""}
                  onChange={(_event, option) => {
                    if (option) {
                      onSelectCompanyType(String(option.key));
                    }
                  }}
                ></Dropdown>
              )}
            </div>
            <div className="ms-Grid-col ms-sm4 ">
              <DefaultButton
                onClick={onClickAssignationUniform}
                className="float-right"
                text={t("Asignación uniforme")}
              />
            </div>
          </div>
          <div className="ms-Grid-row mt-2">
            <div className="ms-Grid-col ms-sm12">
              {!props.formProjectDistribution.isValid() && (
                <ErrorListForForm customForm={props.formProjectDistribution} />
              )}
            </div>
          </div>
          <div className="ms-Grid-row mt-2">
            <div className="ms-Grid-col ms-sm12 ">
              <ToggleInput
                input={props.formProject.getCanViewDistributionInput()}
                onBlur={props.onBlurCustomInput}
                onText="Usuarios pueden visualizar la distribución en actividades"
                offText="Usuarios no pueden visualizar la distribución en actividades"
                onChangeExtended={onChangeCanViewDistribution}
              />

              <ToggleInput
                input={props.formProject.getCanEditDistributionInput()}
                onBlur={props.onBlurCustomInput}
                onText="Usuarios pueden editar la distribución en actividades"
                offText="Usuarios no pueden editar la distribución en actividades"
                onChangeExtended={onChangeCanEditDistribution}
              />
            </div>
          </div>
          <div className="ms-Grid">
            <div
              className="ms-Grid-row"
              style={{
                justifyContent: "flex-end",
                display: "flex",
                marginRight: 1,
              }}
            >
              <TooltipHost content="Copiar como Excel">
                <DefaultButton
                  iconProps={{ iconName: "Copy" }}
                  ariaLabel="Copiar"
                  styles={{
                    root: {
                      minWidth: "32px",
                      padding: 0,
                      border: "none",
                    },
                  }}
                  onClick={() => copyToClipboard(dataToExcel())}
                />
              </TooltipHost>
              <TooltipHost content="Pegar desde Excel">
                <DefaultButton
                  iconProps={{ iconName: "Paste" }}
                  ariaLabel="Pegar"
                  styles={{
                    root: {
                      minWidth: "32px",
                      padding: 0,
                      border: "none",
                    },
                  }}
                  onClick={dataFromExcel}
                />
              </TooltipHost>
            </div>
          </div>
          <div className="ms-Grid-row mt-2">
            <div className="ms-Grid-col ms-sm12 ">
              {companyOptionsFiltered && (
                <ComboBoxSearch
                  options={companyOptionsFiltered.map((comOption: Company) => {
                    let ret: IDropdownOption = {
                      key: comOption.getId(),
                      text: comOption.getName(),
                    };
                    return ret;
                  })}
                  selected={["-1"]}
                  onSelectOption={(option) => {
                    if (option) {
                      onSelectCompany(String(option.key));
                    }
                  }}
                  cleanOnSelect={true}
                ></ComboBoxSearch>
              )}
            </div>
          </div>
          {
            NotCompanies?.map((company,i) => {
              return (
                <div key={i} className="ms-Grid-row mt-1">
                  <div className="ms-Grid-col ms-sm8">
                    <Text
                      styles={{
                        root: {
                          color: "red",
                        },
                      }}
                      variant="medium"
                    >
                      <Icon iconName="EMI" className="text-icon" />
                      {company[0]}
                    </Text>
                  </div>
                  <div
                    style={{
                      color: "red",
                    }}
                    className="ms-Grid-col ms-sm4 "
                  >
                    <span>Empresa no existente eliminada.</span>
                  </div>
                </div>
              );
            })
          }
          {isActive && (
            <div className='mt-2 mb-2'>
              <ToastErrorAndExit
                message="Datos copiados al portapapeles. Pegalo en un Excel."
                tipe="exit"
                closeToast={handleCloseActive}
              />
            </div>
          )}
          {props.projectDistribution.map((projectCompany, i) => {
            return (
              <div key={i} className="ms-Grid-row mt-1">
                <div className="ms-Grid-col ms-sm8 ">
                  <Text variant="medium">
                    <Icon iconName="EMI" className="text-icon" />
                    {projectCompany.getCompany().getName()}
                  </Text>
                </div>
                {props.formProjectDistribution
                  .getInputDistribucionFacturableItems()
                  .getFormFor(projectCompany) && (
                  <div className="ms-Grid-col ms-sm3 ">
                    <InputField
                      input={props.formProjectDistribution
                        .getInputDistribucionFacturableItems()
                        .getFormFor(projectCompany)
                        .getPercentageDistributionInput()}
                      label=""
                      onBlur={(ci) => onBlurPercentage(ci, projectCompany)}
                      propsInput={{
                        placeholder: "Asig.",
                        suffix: "%",
                        maxLength: 5,
                        title: "Porcentaje de asignación",
                      }}
                    />
                  </div>
                )}

                <div className="ms-Grid-col ms-sm1 mt-1">
                  <Icon
                    iconName="Trash"
                    className="cursor-pointer"
                    title="Eliminar del equipo"
                    onClick={() => onDeleteItem(projectCompany)}
                  />
                </div>
              </div>
            );
          })}
          <div className="ms-Grid-row mt-1">
            <div
              className="ms-Grid-col ms-sm9 "
              style={{ visibility: "hidden" }}
            >
              <Text variant="medium">
                <Icon iconName="EMI" className="text-icon" />
                {/* {projectCompany.getCompany().getName()} */}-
              </Text>
            </div>

            <div className="ms-Grid-col ms-sm2 ">
              <div
                className={getTotalPercent() > 100 ? "error-text-red" : ""}
                style={{
                  textAlign: "right",
                  paddingRight: "5px",
                  paddingTop: "10px",
                }}
              >
                {t("Asignación total: ") + getTotalPercent() + "%"}
              </div>
            </div>

            <div
              className="ms-Grid-col ms-sm1 mt-1"
              style={{ visibility: "hidden" }}
            >
              <Icon
                iconName="Trash"
                className="cursor-pointer"
                title="Eliminar del equipo"
                // onClick={() => onDeleteItem(projectCompany)}
              />
            </div>
          </div>
        </div>
        {!props.formProject.isNew() && props.user.hasPowerUserPrivileges() && (
          <>
            <div className="ms-Grid-col ms-sm12 mb-1">
              <Text
                variant="smallPlus"
                className="link-styles"
                onClick={() =>
                  navigate(
                    `${routes.view_distribution_by_period}/${props.formProject
                      .getIdInput()
                      .getValue()}`
                  )
                }
              >
                Ver las distribuciones de actividades ya generadas
              </Text>
            </div>
            <div className="ms-Grid-col ms-sm12">
              <Text
                variant="smallPlus"
                className="link-styles"
                onClick={() =>
                  navigate(
                    `${routes.edit_distribution_by_period}/${props.formProject
                      .getIdInput()
                      .getValue()}`
                  )
                }
              >
                Necesitas corregir las distribuciones de tus actividades ya
                generadas? Hazlo aquí.
              </Text>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
