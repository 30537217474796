import { useState } from 'react';

function useClipboardExcel() {

  const [pastedData, setPastedData] = useState<any[]>([]);
  const [isActive, setIsActive] = useState<boolean>(false);

  const convertToExcelFormat = (data) => {
    return data.map((row) => row.join('\t')).join('\n');
  };

  const copyToClipboard = (data) => {
    const excelData = convertToExcelFormat(data);
    navigator.clipboard
      .writeText(excelData)
      .then(() => {
        setIsActive(true);
      })
      .catch((err) => {
        console.error('Error al copiar los datos:', err);
      });
  };

  const pasteFromClipboard = async (callback:any = null) => {
    try {
      setPastedData([]);
      const pastedText = await navigator.clipboard.readText();
      const processedData  = processPastedData(pastedText);
      if (callback) callback(processedData);
      setPastedData(processedData);
      return processedData;
    } catch (error) {
      console.error('Error al leer del portapapeles:', error);
      alert('Error al leer el portapapeles. Asegúrate de haber copiado algo.');
    }
  };

  const processPastedData = (pastedText) => {
    const rows = pastedText.split('\n').filter((row) => row.trim() !== '');
    return rows.map((row) =>
      row.split('\t').map((cell) => cell.trim())
    );
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
        const pastedText = clipboardData.getData('text');
        processPastedData(pastedText);
    }
  };

  const handleCloseActive = () => {
    setIsActive(!isActive);
  }

  return {
    pastedData,
    copyToClipboard,
    pasteFromClipboard,
    handlePaste,
    isActive,
    handleCloseActive
  };
}

export default useClipboardExcel;