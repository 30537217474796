import React, { useEffect, useRef, useState } from 'react';
import { DefaultButton, IContextualMenuItemStyles, IContextualMenuProps, IContextualMenuStyles ,TextField, Dropdown, IDropdownOption, IDropdownStyles, ComboBox, IComboBoxOption, IComboBoxStyles, IComboBox, Icon, mergeStyleSets, DatePicker, IDatePickerProps} from '@fluentui/react';
import { IconPapers } from './FileUpload';
import { getLocalizedStrings } from '../../lib/LocalizedStringsForDatePicker';
import { DateCustom } from '../../lib/DateCustom';

interface StatusIconProps {
  valida: number;
}

interface HourMinuteInputProps {
  horasCarg: string;
  onHourChange: (id: string, value: string, type: 'hour' | 'minute') => void;
  rowId: string;
}

const FormUploadHeader = () => (
  <div className="ms-Grid-row ms-bgColor-gray20" style={{ display: "flex", alignItems: "center", padding: "20px" }}>
    <Icon iconName="PreviewLink" style={{ fontSize: "25px", color: "#106ebe", marginRight: "10px" }} />
    Carga por formulario
  </div>
);


const ImportAct = ()=>{

  const menuItemStyles: IContextualMenuItemStyles = {
    root: {
      selectors: {
        ':hover': {
          backgroundColor: '#0078D4', 
          color: 'white', 
        },
        ':focus': {
          backgroundColor: '#0078D4', 
          color: 'white',
        },
      },
    },
  };

  const menuStyles: Partial<IContextualMenuStyles> = {
    container: {
      width: 170, 
    },
  };

const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'descargarPlantilla',
        text: 'Descargar Plantilla',
        onClick: () => console.log('Descargando plantilla...'),
        itemProps: { styles: menuItemStyles },
      },
      {
        key: 'subirPlantilla',
        text: 'Subir plantilla',
        onClick: () => console.log('Subiendo plantilla...'),
        itemProps: { styles: menuItemStyles },
      },
    ],
    styles:menuStyles
  };

  return (
    <DefaultButton
      disabled={true}
      iconProps={ {iconName: 'ExcelLogo'}}
      text="Importar act"
      menuProps={menuProps}
      split={true}
      style={{width:"170px",color:"#0078D4",border: "1px solid #0078D4"}}
    />
  );
};


const HourMinuteInput: React.FC<HourMinuteInputProps> = ({
  horasCarg,
  onHourChange,
  rowId
}) => {

  const customStyles: Partial<IDropdownStyles> = {
    caretDown: {
      display: 'none'
    },
  };

  const minutoOptions: IDropdownOption[] = [
    { key: '00', text: '00' },
    { key: '15', text: '15' },
    { key: '30', text: '30' },
    { key: '45', text: '45' },
  ];
  return (
    <div >
      <TextField
        placeholder="hh"
        className="absolute horas"
        value={horasCarg.split(':')[0]}
        onChange={(e, newValue) => onHourChange(rowId, newValue || '', 'hour')}
      />
      <div className="absolute dosPuntos" style={{ marginTop: '-32px' }}>:</div>
      <Dropdown
        className="absolute minutos"
        styles={customStyles}
        options={minutoOptions}
        placeholder={'mm'}
        selectedKey={horasCarg.split(':')[1]}
        onChange={(event, option) => {
          if (option) onHourChange(rowId, String(option.key), 'minute');
        }}
      />
    </div>
  );
};


const StatusIcon: React.FC<StatusIconProps> = ({ valida }) => {
  if (valida === 2) {
    return <IconPapers key="004" nameIcon="AlertSolid" color="yellow" size="25px" thickness={0} />;
  } else if (valida === 0) {
    return <IconPapers key="003" nameIcon="ChromeClose" color="red" size="25px" thickness={9} />;
  } else {
    return <IconPapers key="002" nameIcon="Accept" color="green" size="25px" thickness={9} />;
  }
};

interface CustomDatePickerProps {
    strings: IDatePickerProps['strings'];
    label: string;
    value: Date;
    blockedMonths: { [key: number]: number[] };
    onSelectDate: (date: Date | null | undefined) => void;
}

const CustomDatePicker: React.FunctionComponent<CustomDatePickerProps> = ({ strings, label, value, blockedMonths, onSelectDate }) => {
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const yearObserverRef = useRef<MutationObserver | null>(null);

    const observeYearChanges = () => {
        const holaElement = document.querySelector('.hola-date-2');
        if (holaElement) {
            const focusZoneElement = holaElement.querySelector('.ms-FocusZone');
            if (focusZoneElement) {
                const yearElement = focusZoneElement.querySelector('div[aria-label]');
                if (yearElement) {
                    if (yearObserverRef.current) {
                        yearObserverRef.current.disconnect();
                    }

                    yearObserverRef.current = new MutationObserver((mutationsList) => {
                        for (const mutation of mutationsList) {
                            if (mutation.type === 'childList' || mutation.type === 'characterData') {
                                const year = yearElement.getAttribute('aria-label');
                                if (year) {
                                    disableBlockedMonths(parseInt(year, 10));
                                }
                            }
                        }
                    });

                    const config = { childList: true, subtree: true, characterData: true };
                    yearObserverRef.current.observe(yearElement, config);

                    const year = yearElement.getAttribute('aria-label');
                    if (year) {
                        disableBlockedMonths(parseInt(year, 10));
                    }
                }
            }
        }
    };

    const disableBlockedMonths = (year: number) => {
        const holaElement = document.querySelector('.hola-date-2');
        if (holaElement) {
            const focusZoneElement = holaElement.querySelector('.ms-FocusZone');
            if (focusZoneElement) {
                const blockedMonthsForYear = blockedMonths[year] || [];
                blockedMonthsForYear.forEach(month => {
                    const monthButton = focusZoneElement.querySelector(`button[aria-label="${getMonthName(month-1)}"]`);
                    if (monthButton) {
                        monthButton.classList.add('disabled-month');
                    }
                });
            }
        }
    };

    const getMonthName = (month: number): string => {
        const monthNames = [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ];
        return monthNames[month];
    };

    useEffect(() => {
        if (isDatePickerOpen) {
            // Usar setTimeout para asegurarse de que el DOM esté completamente renderizado
            setTimeout(() => {
                observeYearChanges();
            }, 100);
        }

        return () => {
            if (yearObserverRef.current) {
                yearObserverRef.current.disconnect();
                yearObserverRef.current = null;
            }
        };
    }, [isDatePickerOpen]);

    const onClick = () => {
        setIsDatePickerOpen(true);

        // Esperar un momento para permitir que el calendario se renderice
        setTimeout(() => {
            observeYearChanges();
        }, 100); // Ajusta el tiempo según sea necesario
    };

    const handleSelectDate = (date: Date | null | undefined) => {
        setIsDatePickerOpen(false);
        onSelectDate(date); // Llama a la función que maneja el cambio de fecha

        // Desconectar el observador cuando se cierra el DatePicker
        if (yearObserverRef.current) {
            yearObserverRef.current.disconnect();
            yearObserverRef.current = null;
        }
    };

    return (
        <DatePicker
            strings={strings}
            label={label}
            value={value}
            formatDate={DateCustom.formatDateForDatePicker_MMYYYY}
            onSelectDate={handleSelectDate}
            className="hola-date ms-Grid-col ms-sm6 ms-md4 ms-lg4"
            calendarProps={{ isDayPickerVisible: false, className: 'hola-date-2' }}
            onClick={onClick}
            onClickCapture={onClick}  
        />
    );
};

export  {ImportAct,HourMinuteInput,StatusIcon,FormUploadHeader,CustomDatePicker};

{/*
  <div className="ms-Grid-col ms-sm12"  >
  <TextField 
    placeholder="hh" 
    className="absolute horas"
    value={row.horasCarg.split(':')[0]} 
    onChange={(e, newValue) => HandlerContHoras(row.id, newValue, 'hour')}
  />
   <div className="absolute dosPuntos" style={{marginTop:"-32px"}} >:</div>
  <Dropdown
  className="absolute minutos"
  styles={customStyles}
    options={minutoOptions}
    placeholder={'mm'}
    selectedKey={row.horasCarg.split(':')[1]} 
    onChange={(event, option) => {
      if (option) HandlerContHoras(row.id, String(option.key), 'minute');
    }}
  />
</div>


          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg7">
                <ComboBox label="Colaborador" className="ms-Grid-col ms-sm4 ms-md6 ms-lg4" options={filteredCollaborators} 
                  allowFreeform text={collaboratorValue} onChange={(_,option)=>handleSelectCollaborator(option)}
                  onInputValueChange={(text)=>handleFilterCollaborator(text || "")} selectedKey={"00000006454"}
                />
                <DatePicker label="Periodo" className="ms-Grid-col ms-sm6 ms-md4 ms-lg4"  strings={getLocalizedStrings()}
                formatDate={DateCustom.formatDateForDatePicker_MMYYYY} calendarProps={{ isDayPickerVisible: false }}
                value={periodo}  onSelectDate={(e) => {
                  if (e) {
                    let _date = new Date(e.getFullYear(), e.getMonth(), 1);
                    HandlerPeriodo(_date);
                  }
                }}
                />
                <div  className="ms-Grid-col ms-sm4" >
                  <Label >Horas pendientes</Label>
                  <DefaultButton style={{color:horasTotal > -1 ? "black":"red",border:"none"}} >{convertMinToHour(horasTotal)}</DefaultButton>
                </div>
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1" style={{ display: "flex", justifyContent: "center" }}>
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4" style={{ display: "flex", justifyContent: "flex-end", gap: "20px", marginTop: "20px" }}>
                <PrimaryButton className="ms-Grid-col ms-sm12 ms-md6 ms-lg3" text="Cargar" 
                iconProps={UploadIcon} type="submit" disabled={desabled} />
              </div>
            </div>
          </div>
          <div  className="ms-Grid-row"  style={{padding:"30px 0px 10px 30px"}}>
            <h4  className="ms-Grid-col ms-sm11"  >Tienes {actError.actVali} filas válidas y {actError.actvErro} filas con errores</h4 >
          </div>
          <div className="ms-Grid-row" style={{padding:"0px 30px"}}>
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
              <div className="ms-Grid-col ms-sm9" style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                <PrimaryButton disabled={projectsList.length === 0? true :desableAddRow?true:false} text="Agregar fila" style={{width:"150px",marginRight:"10px"}} iconProps={AddIcon} onClick={()=>HandlerAddRows()} />
                <PrimaryButton text="Limpiar todo" style={{width:"125px"}}/>
              </div>
              <div>
               <ImportAct/>
              </div>
              <div className="ms-Grid-col ms-sm12">
                <div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"20px 20px"}}><Icon iconName="Info" style={{marginRight:"5px"}}/><div>Los documentos serán cargados al proyecto</div></div>    
              </div>
            </div>
          </div>
*/}